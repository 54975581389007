import React, { useState, useEffect } from "react";
import "./App.css";
import EditComponent from "./components/ImportComponent";
import SettingsComponent from "./components/SettingsComponent";
import UserGuide from "./components/UserGuide";

function App() {
  const [activeComponent, setActiveComponent] = useState("Edit");
  const [isDarkMode, setIsDarkMode] = useState(true);

  const [components, setComponents] = useState({
    Edit: <EditComponent />,
    Settings: <SettingsComponent />,
    Export: <UserGuide />,
  });

  const handleComponentClick = (component) => {
    setActiveComponent(component);
  };

  // Toggle dark mode
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  // Apply or remove the dark mode class from body
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  return (
    <div className="App">


      {/* Sidebar */}
      <div className="sidebar">
        <h1>VisiTag</h1>
        <a
          href="/#"
          className={activeComponent === "Edit" ? "active" : ""}
          onClick={() => handleComponentClick("Edit")}
        >
          Edit
        </a>
        <a
          href="/#"
          className={activeComponent === "Settings" ? "active" : ""}
          onClick={() => handleComponentClick("Settings")}
        >
          Settings
        </a>
        <a
          href="/#"
          className={activeComponent === "Export" ? "active" : ""}
          onClick={() => handleComponentClick("Export")}
        >
          User Guide
        </a>
      </div>

      {/* Main content area */}
      <div className="vidContainer">
        {Object.keys(components).map((key) => (
          <div
            key={key}
            className={activeComponent === key ? "active" : ""}
            style={{ display: activeComponent === key ? "block" : "none" }}
          >
            {components[key]}
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
