import React from "react";
import "./UserGuide.css"; // Optional: CSS file for styling

const UserGuide = () => {
  return (
    <div className="user-guide">
      <h1>User Guide</h1>
      <p>Welcome to the VisiTag User Guide. This guide will walk you through the main features and functionality of the application.</p>

      {/* Section 1: Introduction */}
      <section>
        <h2>Introduction</h2>
        <p>
          VisiTag is a simple video annotation tool designed to help you label, manage, and export annotations with ease. Whether you're working on object detection, incident tracking, or any other visual data analysis, this tool is built to simplify the process.
        </p>
      </section>

      {/* Section 2: System Requirements */}
 
      {/* Section 4: Uploading a Video */}
      <section>
        <h2>Uploading a Video</h2>
        <p>Follow these steps to upload a video and begin annotating:</p>
        <ol>
          <li>Click the "Upload" button located on the top-left corner of the screen.</li>
          <li>Select a video from your local machine.</li>
          <li>Wait for the video to load, and you'll be taken to the annotation workspace.</li>
        </ol>
      </section>

      {/* Section 5: Creating Annotations */}
      <section>
        <h2>Creating Annotations</h2>
        <p>
          Once a video is uploaded, you can start creating annotations by following these steps:
        </p>
        <ol>
          <li>Click the "Add a New Box" button on the toolbar.</li>
          <li>Draw bounding boxes around objects by clicking and dragging over the desired area of the video frame.</li>
        </ol>
        <p>Annotations will appear in the right panel, where you can edit, delete, or review them at any time.</p>
      </section>

      {/* Section 6: Managing Annotations */}
      <section>
        <h2>Managing Annotations</h2>
        <p>To manage annotations:</p>
        <ul>
          <li><strong>Edit:</strong> Click on an annotation in the list to split or hide it.</li>
          <li><strong>Delete:</strong> Use the delete button to remove an unwanted annotation.</li>
          <li><strong>History:</strong> The Movement history can be seen in the dropdown below the annotation</li>
        </ul>
      </section>

      {/* Section 7: Exporting Annotations */}
      <section>
        <h2>Exporting Annotations</h2>
        <p>To export your annotations:</p>
        <ol>
          <li>Click the "Submit" button in the editor.</li>
          <li>Download the export file to your local machine.</li>
          <li>To work on previous annotations, simply reupload the JSON file</li>
        </ol>
      </section>

      {/* Section 8: Settings */}
      <section>
        <h2>Settings</h2>
        <p>
          You can customize the appearance and functionality of VisiTag in the settings menu:
        </p>
        <ul>
          <li><strong>Theme:</strong> Switch between light mode and dark mode.</li>
          <li><strong>Text Size:</strong> Adjust text size. Found in Settings</li>
          <li><strong>Playback:</strong> Playback can be adjusted in the video player</li>
        </ul>
      </section>
    </div>
  );
};

export default UserGuide;