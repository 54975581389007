import React, { useRef, useEffect, useState } from 'react';
import { TwoDimensionalImage, TwoDimensionalVideo } from "react-annotation-tool"
import ReactDOMServer from 'react-dom/server'


function returnAnnotation(src) {
  return (<TwoDimensionalVideo
    onSubmit={r => console.log(r)}
    url={src}
    videoWidth={700}
    hasReview
    isEmptyCheckEnable
    isSplitEnable
    isShowHideEnable
    emptyCheckSubmissionWarningText="Example warning text"
    emptyCheckAnnotationItemWarningText="Example item warning text"
    emptyAnnotationReminderText="Reminder text"
    numAnnotationsToBeAdded={20}
    defaultAnnotations={[]}
    previewHeader="Preview Header"
    previewNoticeList={['Notice 1', 'Notice 2']}
  />)
}



function ImportComponent() {
  
  const [annotationHtml, setAnnotationHtml] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [annotations, setAnnotations] = useState([]); // Store the annotations field
  const [jsonError, setJsonError] = useState(""); // To handle any JSON parsing errors

  // Function to handle JSON file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    if (file && file.type === "application/json") {
      const reader = new FileReader(); // Create a FileReader to read the file
      reader.onload = (e) => {
        const fileContent = e.target.result; // Get file content as a string
        try {
          const json = JSON.parse(fileContent); // Parse the JSON file
          if (json.annotations) {
            setAnnotations(json.annotations); // Set annotations to the parsed data
            setJsonError(""); // Clear error if successful
          } else {
            alert("Annotations field not found in the uploaded JSON.");
          }
        } catch (error) {
          setJsonError("Invalid JSON format."); // Set error if JSON parsing fails
          setAnnotations(null);
        }
      };
      reader.readAsText(file); // Read the file as text
    } else {
      setJsonError("Please upload a valid JSON file."); // Handle invalid file type
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file && file.type === "video/mp4") {
      const videoURL = URL.createObjectURL(file);
      setVideoSrc(videoURL);
    } else {
      alert("Please select a mp4 file");
    }
  };

  const handleDownload = (jsonObject) => {
    // Convert JSON object to a string
    const jsonString = JSON.stringify(jsonObject, null, 2); // Pretty print with 2 spaces

    // Create a Blob from the JSON string
    const blob = new Blob([jsonString], { type: 'application/json' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.download = 'data.json'; // Specify the filename

    // Programmatically click the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up: remove the link and revoke the Blob URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };



  useEffect(() => {
    const element = returnAnnotation(videoSrc);
    const htmlString = ReactDOMServer.renderToString(element)
    // console.log('Captured HTML:', htmlString);

    setAnnotationHtml(htmlString);

  }, [videoSrc]);



  //the video player needs a key tag to update the preview, otherwise itll only show the first uploaded vid
  //this is due to how react handles components, check here: https://robfarr.me/blog/react-doesnt-update-html5-video-sources/
  // - Oli
  return (
    <div>
      {videoSrc && (
        <TwoDimensionalVideo
        onSubmit={handleDownload}
        url={videoSrc}
        videoWidth={700}
        hasReview
        isEmptyCheckEnable
        isSplitEnable
        isShowHideEnable
        emptyCheckSubmissionWarningText="Certain Boxes have not been properly configured, please check all warnings"
        emptyCheckAnnotationItemWarningText="No Movement Made"
        emptyAnnotationReminderText="This annotation is empty"
        numAnnotationsToBeAdded={20}
        defaultAnnotations={annotations??[]}
        previewHeader="Please ensure that"
        previewNoticeList={['The JSON uploaded is correct (if it is being included)', 'The video uploaded is correct']}
      />
      )}


      {/*the label is used to hide the default choose file button, since we cant change it directly we have to do it this way*/}
      <label htmlFor="fileInput" className="custom-input">Import</label>
      <input type="file" id="fileInput" className="fileInput" accept="video/mp4" onChange={handleFileChange} />
      <label className="custom-input" htmlFor="jsonUpload">
        Upload JSON
      </label>
      <input
        type="file"
        id="jsonUpload"
        accept=".json"
        className='fileInput'
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />

    </div>
  );
}
export default ImportComponent;
