import React, { useState, useEffect, DetailedHTMLProps } from "react";
import "./SettingsComponent.css";

function SettingsComponent() {
  // State to manage text size and theme
  const [textSize, setTextSize] = useState("medium");
  const [lightMode, setLightMode] = useState(false);
  const [codec, setCodec] = useState("VP9");
  const [outputFormat, setOutput] = useState("JSON");

  // Apply text size and theme based on state changes
  useEffect(() => {
    document.documentElement.style.fontSize = getTextSize(textSize);
    document.body.className = lightMode ? "light-mode" : "dark-mode";
  }, [textSize, lightMode]);

  // Function to map text size state to actual CSS size
  const getTextSize = (size) => {
    switch (size) {
      case "small":
        return "11px";
      case "large":
        return "24px";
      default:
        return "16px";
    }
  };

  // Function to handle text size change
  const handleTextSizeChange = (event) => {
    setTextSize(event.target.value);
  };

  // Function to handle dark mode toggle
  const handleDarkModeToggle = () => {
    setLightMode(!lightMode);
  };

  return (
    <div className="settings-container">
      <h2 className="settingHead">Settings</h2>

      <div className="setting-item">
        <label htmlFor="text-size">Text Size:</label>
        <select id="text-size" value={textSize} onChange={handleTextSizeChange}>
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
        </select>
      </div>

      <div className="setting-item">
        <label htmlFor="dark-mode">Light Mode:</label>
        <input
          type="checkbox"
          id="dark-mode"
          checked={lightMode}
          onChange={handleDarkModeToggle}
        />
      </div>

      <div className="setting-item">
        <label htmlFor="codec">Video Codec:</label>
        <select id="codec" value={codec} onChange={(event) => {setCodec(event.target.value)}}>
          <option value="AV1">AV1</option>
          <option value="AVC (H.264)">AVC (H.264)</option>
          <option value="HEVC (H.265)">HEVC (H.265)</option>
          <option value="MP4V-ES">MP4V-ES</option>
          <option value="MPEG-2">MPEG-2</option>
          <option value="VP9">VP9</option>
        </select>
      </div>

      <div className="setting-item">
        <label htmlFor="video-format">Video Format:</label>
        <select id="video-format" value={"MP4"}>
          <option value="MP4">MP4</option>
          <option value="MOV">MOV</option>
          <option value="AVI">AVI</option>
          <option value="WMV">WMV</option>
        </select>
      </div>

      <div className="setting-item">
        <label htmlFor="output-format">Output Format:</label>
        <select id="output-format" value={outputFormat} onChange={(event) => {setOutput(event.target.value)}}>
          <option value="JSON">JSON</option>
          <option value="CSV">CSV</option>
          <option value="torch">torch</option>
          <option value="tesnor">tensor</option>
        </select>
      </div>
    </div>
  );
}

export default SettingsComponent;
